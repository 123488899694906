<div class="layout-wrapper" [ngClass]="{
    'layout-overlay': configService.menuMode === 'overlay',
    'layout-static': configService.menuMode === 'static',
    'layout-slim': configService.menuMode === 'slim',
    'layout-horizontal': configService.menuMode === 'horizontal',
    'layout-sidebar-dim': configService.colorScheme === 'dim',
    'layout-sidebar-dark': configService.colorScheme === 'dark',
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && configService.menuMode === 'static',
    'p-input-filled': configService.inputStyle === 'filled',
    'p-ripple-disabled': !configService.ripple}"
     [class]="configService.colorScheme === 'light' ? configService.menuTheme : ''"
     [attr.data-theme]="configService.colorScheme" (click)="onLayoutClick()">

    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>

        <div class="layout-content" [ngClass]="{'layout-content-full-page': welcomePageService.isInWelcomePage$ | async}">
            <router-outlet></router-outlet>
        </div>
    </div>

    <app-right-menu></app-right-menu>
</div>
