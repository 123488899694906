import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';

import { timeZoneFormatter } from '@core/utilities';

@Component({
    selector: 'app-time-zone-container',
    templateUrl: './time-zone-container.component.html',
    styleUrls: ['./time-zone-container.component.scss']
})
export class TimeZoneContainerComponent implements OnInit, OnChanges {
    public now: Date = new Date();
    public formattedTimeZoneOffset: string;

    @Input() timeZoneOffset: number;
    @Output() updateTimeZone: EventEmitter<number> = new EventEmitter<number>();
    @Input() viewMode: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.timeZoneOffset.currentValue !== changes.timeZoneOffset.previousValue) {
            this.updateTime();
            this.updateTimeZoneFormat();
        }
    }

    private updateTime(): void {
        setInterval(() => {
            this.now = new Date();
        }, 1);
    }

    public GMTPlusMinus(type: string): void {
        if (type === 'plus' && this.timeZoneOffset !== 14) {
            switch (this.timeZoneOffset) {
                case 5.5:
                case 5.75:
                case 8.75:
                case 12.75:
                    this.timeZoneOffset += 0.25;
                    break;
                case -10:
                case -9.5:
                case -2.5:
                case -4:
                case -3.5:
                case 3:
                case 3.5:
                case 4:
                case 4.5:
                case 5:
                case 6:
                case 6.5:
                case 9:
                case 9.5:
                case 10.00:
                case 10.5:
                    this.timeZoneOffset += 0.5;
                    break;
                case 8.00:
                case 12.00:
                    this.timeZoneOffset += 0.75;
                    break;
                default:
                    this.timeZoneOffset += 1;
                    break;
            }
        } else if (type === 'minus' && this.timeZoneOffset !== -12) {
            switch (this.timeZoneOffset) {
                case 5.75:
                case 6:
                case 9:
                case 13:
                    this.timeZoneOffset -= 0.25;
                    break;
                case -3:
                case -3.5:
                case -9:
                case -9.5:
                case 3.5:
                case 4:
                case 4.5:
                case 5:
                case 5.5:
                case 6.5:
                case 7:
                case 9.5:
                case 10:
                case 10.5:
                case 11:
                    this.timeZoneOffset -= 0.5;
                    break;
                case 8.75:
                case 12.75:
                    this.timeZoneOffset -= 0.75;
                    break;
                default:
                    this.timeZoneOffset -= 1;
                    break;
            }
        }
        this.updateTimeZoneFormat();
    }

    public updateTimeZoneFormat(): void {
        this.formattedTimeZoneOffset = timeZoneFormatter(this.timeZoneOffset);
        this.updateTimeZone.emit(this.timeZoneOffset);
    }

}
