import { IPartnerPasswordRegExp } from '@core/interfaces';
import { PasswordRegExpEnum } from '@core/enums';

export function generatePartnerObjectFromRegExp(regexp: string): IPartnerPasswordRegExp {
    const passwordLength = regexp.match(/\.{(.*)\}/);

    const regexpObject: IPartnerPasswordRegExp = {
        Uppercase: !regexp.includes(PasswordRegExpEnum.UnableUppercase),
        IsUppercaseRequired: regexp.includes(PasswordRegExpEnum.IsUppercaseRequired),
        Lowercase: !regexp.includes(PasswordRegExpEnum.UnableLowercase),
        IsLowercaseRequired: regexp.includes(PasswordRegExpEnum.IsLowercaseRequired),
        Symbol: !regexp.includes(PasswordRegExpEnum.UnableSymbol),
        IsSymbolRequired: regexp.includes(PasswordRegExpEnum.IsSymbolRequired),
        Numeric: !regexp.includes(PasswordRegExpEnum.UnableNumeric),
        IsNumericRequired: regexp.includes(PasswordRegExpEnum.IsNumericRequired),
        MinLength: passwordLength ? +passwordLength[1].split(',')[0] : 0,
        MaxLength: passwordLength ? +passwordLength[1].split(',')[1] : 0,
    };

    return regexpObject;
}

export function generateRegExpFromPartnerObject(partnerObject): string {
    let regexp = '^(?!.*[^\\S\\w`!@#$%^&*()\\|_+\\-=\\[\\]{};\':"\\,.<>\\/?~])';

    for (const key in partnerObject) {
        if (
            partnerObject[key] &&
            (key !== 'MinLength' && key !== 'MaxLength')
        ) {
            regexp += PasswordRegExpEnum[key];
        } else if (
            !partnerObject[key] &&
            !partnerObject['Is' + key + 'Required'] &&
             PasswordRegExpEnum['Unable' + key])
        {
            regexp += PasswordRegExpEnum['Unable' + key];
        }
    }

    return regexp + `.{${partnerObject.MinLength + ',' + (partnerObject.MaxLength || '')}}$`;
}
