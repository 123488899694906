import { Injectable } from '@angular/core';
import { HttpService } from '@core/services';
import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import { Observable } from 'rxjs';
import { IResponseData } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class BetsService {

    constructor(private http: HttpService) {}

    public getBets(filterOptions: any): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Report',
            Method: 'GetInternetBetsReportPaging',
            RequestObject: {
                ...filterOptions,
                filterModel: {}
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getBetDetail(betId: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Report',
            Method: 'GetBetInfo',
            RequestObject: betId
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public updateBetSettings(data: any): Observable<any> {
        return this.http.request('post',
            environment.ApiUrl + Methods.UPDATE_BET_SETTINGS,
            data,
            false,
            null,
            true)
    }
}
