import { TranslateService } from "@ngx-translate/core";
import { IEnum } from "@core/interfaces";

/**
 * @desc Transforms an array of enum-like objects based on the provided flags.
 * @param {any[]} enumArray - The array of enum-like objects to be transformed.
 * @param {TranslateService} translateService - The translation service for handling translations.
 * @param {boolean} isArrayOfString - Flag indicating if the transformation is for an array of strings.
 * @param {boolean} isTransformOfLink - Flag indicating if the transformation is for links.
 * @param {string} translationKey - Key used for translation. Pass an empty string if not applicable.
 * @returns {any[]} - The transformed array based on the specified flags.
 */
export function transformEnum(
    enumArray: any[] = [],
    translateService: TranslateService = null,
    isArrayOfString: boolean = false,
    isTransformOfLink: boolean = false,
    translationKey: string = '',
): any {
    enumArray = filterNullValues(enumArray);

    switch (true) {
        case isTransformOfLink:
            return transformToEnumLinks(enumArray);
        case isArrayOfString:
            return transformArrayOfStrings(enumArray, translateService, translationKey);
        default:
            return transformDefault(enumArray, translateService, translationKey);
    }
}

/**
 * @desc Filters null values from an array.
 * @param {any[]} array - The array to filter.
 * @returns {any[]} - The array with null values removed.
 */
function filterNullValues(array: any[]): any[] {
    return array.filter(item => item !== null);
}

/**
 * @desc Transforms an array of objects by mapping each item to an IEnum object with 'Name' and 'Value' properties.
 * @param {any[]} array - The array to transform.
 * @returns {any[]} - The transformed array.
 */
function transformToEnumLinks(array: any[]): any[] {
    return array.map((item) => ({
        Name: item.Link,
        Value: item.Id,
    } as IEnum));
}

/**
 * @desc Transforms an array of strings by mapping each item to an IEnum object with 'Name', 'Value', and 'TranslatedName' properties.
 * @param {any[]} array - The array of strings to transform.
 * @param {TranslateService} translateService - The translation service for handling translations.
 * @param translationKey - The translation prefix
 * @returns {any[]} - The transformed array.
 */
function transformArrayOfStrings(array: any[], translateService: TranslateService, translationKey: string ): any[] {
    return array.map((item) => ({
        Name: item,
        Value: item,
        TranslatedName: translateService ? translateService.instant(`${translationKey}` + item) : item,
    } as IEnum));
}

/**
 * @desc Transforms an array of objects by assuming it contains objects with a 'Name' property.
 * Each item is mapped to an object with a 'TranslatedName' property using the translation service.
 * @param {any[]} array - The array to transform.
 * @param {TranslateService} translateService - The translation service for handling translations.
 * @param translationKey The translation prefix
 * @returns {any[]} - The transformed array.
 */
function transformDefault(array: any[], translateService: TranslateService, translationKey: string): any[] {
    return array.map((item) => ({
        ...item,
        TranslatedName: translateService.instant(`${translationKey}` + item.Name),
    } as IEnum));
}

