import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '@core/services/config.service';

@Injectable()
export class WelcomePageService {

    public isInWelcomePage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private configService: ConfigService) {}

    public initExternalAnimationScript(): void {
        const src: string = 'assets/scripts/welcome-page-animation.js';
        const type: string = 'module';
        const id: string = 'welcome-page-animation';

        this.configService.loadExternalScript(src, type, id);
    }

    public removeAnimationCanvas(): void {
        document.querySelector('.welcome-page-canvas')?.remove();
        document.getElementById('welcome-page-animation')?.remove();
    }
}
