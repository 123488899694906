import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let unsubscribe$: Subject<void> = new Subject<void>();

export function onEdit(params): void {
    const index = params.rowIndex ? params.rowIndex : params.node.rowIndex;

    params.node.data.editRowIndex = index;

    params.api.selectIndex(index, false, false);
    params.api.startEditingCell({
        rowIndex: index,
        colKey: params.column.getColId(),
    });

    hideContextMenuOnClick();
    subscribeToDocumentClick(params);
}

export function onSave(params): void {
    params.api.redrawRows({ rowNodes: [params.node] });
    params.node.data.editRowIndex = null;
    unsubscribe$.next();
    unsubscribe$.complete();
}

export function onCancel(params): void {
    params.api.stopEditing(true);
    params.node.data.editRowIndex = null;
    unsubscribe$.next();
    unsubscribe$.complete();
}

export function hideContextMenuOnClick(): void {
    const editInput: HTMLInputElement = document.querySelector('input[ref="eInput"][aria-label="Input Editor"]');
    editInput.setSelectionRange(0, 0);
}

export function subscribeToDocumentClick(params): void {
    unsubscribe$.next();
    unsubscribe$.complete();
    unsubscribe$ = new Subject<void>();
    fromEvent(document, 'click')
        .pipe(takeUntil(unsubscribe$))
        .subscribe((event: any) => {
            const target: any = event.target;

            if (
                !target.closest('.ag-cell-inline-editing') &&
                !target.classList.contains('icon-edit') &&
                !target.closest('.ag-menu')
            ) {
                stopEditing(params);
            }
        });
}

export function stopEditing(params): void {
    const index = params.rowIndex ? params.rowIndex : params.node.rowIndex;

    params.api.selectIndex(index, false, false);
    params.api.stopEditing();
    unsubscribe$.next();
    unsubscribe$.complete();
}

export function copyToClipboard(value: string): void {
    if (navigator.clipboard && value) {
        navigator.clipboard.writeText(value);
    }
}

export async function readFromClipboard(): Promise<string | void> {
    if (navigator.clipboard) {
        return await navigator.clipboard.readText();
    }
}
