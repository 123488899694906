import { HttpService } from '@core/services';
import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    pairwise,
    startWith,
    switchMap,
    take
} from 'rxjs';

import { IEnum, IResponseDataWithCount, ITranslation, ITranslationsCopyData } from '@core/interfaces';
import { environment } from '@env/environment';
import { Methods } from '@core/enums';

@Injectable({
    providedIn: 'root'
})

export class TranslationsService {
    private pendingCopyPartnerTranslations$: BehaviorSubject<ITranslationsCopyData[]> = new BehaviorSubject([]);
    public detailRendererRefreshManually$: Subject<boolean> = new Subject();
    public updatedTranslationsTrigger: EventEmitter<void> = new EventEmitter();

    constructor(
        private http: HttpService,
    ) {
    }

    public getTranslations(payload): Observable<IResponseDataWithCount<ITranslation[]>> {
        return this.http.request(
            'post',
            environment.CMSApiUrl + Methods.GET_TRANSLATIONS,
            payload
        );
    }

    public addTranslation(payload: any): Observable<ITranslation> {
        return this.http.request(
            'post',
            environment.CMSApiUrl + Methods.ADD_TRANSLATION,
            payload
        );
    }

    public updateTranslationJson(queryParams: any): Observable<any> {
        return this.http.request(
            'post',
            environment.CMSApiUrl + Methods.UPDATE_TRANSLATION_JSON,
            null,
            false,
            { params: queryParams },
            false
        );
    }

    public editTranslation(payload: any): Observable<any> {
        return this.http.request(
            'put',
            environment.CMSApiUrl + Methods.EDIT_TRANSLATION,
            payload
        );
    }

    public deleteTranslation(obj: ITranslation): Observable<null> {
        return this.http.request(
            'delete',
            environment.CMSApiUrl + Methods.REMOVE_TRANSLATION,
            null,
            false,
            { body: obj },
            false
        );
    }

    public getPartnerTranslationKeys(partnerId: number): Observable<IEnum[]> {
        return this.http.request(
            'get',
            environment.CMSApiUrl + Methods.GET_PARTNER_TRANSLATION_KEYS,
            null,
            false,
            { params: { partnerId } },
            false
        );
    }

    public getProjectTypes(): Observable<any> {
        return this.http.request(
            'get',
            environment.CMSApiUrl + Methods.GET_PROJECT_TYPES,
        );
    }

    public copyTranslationsFromPartner(payload: any): Observable<void> {
        return this.http.request(
            'post',
            environment.CMSApiUrl + Methods.COPY_TRANSLATIONS_FROM_PARTNER,
            payload,
            false,
            null,
            false
        );
    }

    public getPendingCopyPartnerTranslations(): Observable<ITranslationsCopyData[][]> {
        return this.http.request(
                'get',
                environment.CMSApiUrl +
                    Methods.GET_PENDING_COPY_PARTNER_TRANSLATIONS
            )
            .pipe(
                switchMap((data: ITranslationsCopyData[]) => {
                    this.pendingCopyPartnerTranslations$.next(data);
                    return this.pendingCopyPartnerTranslations$;
                }),
                startWith(this.pendingCopyPartnerTranslations$.getValue()),
                pairwise(),
                take(1),
            );
    }

    public getTranslationSubKeys(partnerId: number, parentName: string): Observable<string[]> {
        return this.http.request(
            'get',
            `${environment.CMSApiUrl}${Methods.GET_TRANSLATION_SUB_KEYS}/${partnerId}/${parentName}`
        );
    }
}
