export enum RegexesEnum  {
    IgnoreCaseFlag = 'i',
    GlobalSearchFlag = 'g',
    MultilineFlag = 'm',
    UnicodeFlag = 'u',
    StickyFlag = 'y',
    DoToAllFlag = 's',

    AnyWordCharPatternRegex = '\w*',
    NamePatternRegex = '^(?=.*[A-Z]|[a-z]|[0-9])([A-Za-z0-9.\s_-]+)$',
    UrlPatternRegex = '^((([a-z]|\\d|-)*(\\.|:|\\/)+([a-z]|\\d)*)(,?))+$',
    NumberPatternRegex = '^[0-9]+$',
    PartnerGroupNamePatternRegex = '[a-zA-Z0-9+_@.-]*$',
    MobileNumberPatternRegex = '^[\+][(]?[0-9]{3}[)]?[0-9]{2,16}$',

    HEXPattern = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$',
    RGBAPattern = '^rgba\\((\\d{1,3}),\\s*(\\d{1,3}),\\s*(\\d{1,3}),\\s*(0|1|0?\\.\\d+)\\)$',
    HSLAPattern = '^hsla\\((\\d{1,3}),\\s*(\\d{1,3})%,\\s*(\\d{1,3})%,\\s*(0|1|0?\\.\\d+)\\)$',
    HEXPatternInGradient = '^(linear|radial)-gradient\\([\\s\\S]*#[0-9a-fA-F]{3,6}[\\s\\S]*\\)$',
    RGBAPatternInGradient = '^(linear|radial)-gradient\\([\\s\\S]*rgba\\([\\s\\S]*\\)[\\s\\S]*\\)$',
    HSLAPatternInGradient = '^(linear|radial)-gradient\\([\\s\\S]*hsla?\\([\\s\\S]*\\)[\\s\\S]*\\)$',
}