import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ICellRendererParams } from 'ag-grid-community';

import { environment } from '@env/environment';
import { Methods, StatusesEnum } from '@core/enums';
import { AuthService, HttpService } from '@core/services';
import {
    IObjectTranslation,
    IPaymentSystem,
    IResponseData,
    ISelectItem
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    public paymentRequestStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>([]);
    public paymentRequestDetailInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public paymentSystems$: BehaviorSubject<IPaymentSystem[]> = new BehaviorSubject<IPaymentSystem[]>([]);
    public statusChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        private http: HttpService,
        private authService: AuthService,
    ) {
        this.loadPaymentRequestsStates();
    }

    public getDeposits(): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Dashboard',
            Method: 'GetDeposits',
            RequestObject: {
                FromDate: "2023-01-26T20:00:00.000Z",
                SkipCount: 0,
                TakeCount: 100,
                ToDate: "2023-01-27T20:00:00.000Z"
            }
        };

        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getGgr(ClientId: number): Observable<IResponseData<number>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientGGR',
            ClientId,
            RequestObject: {}
        };
        const queryParams = {
            TimeZone: 4.5,
            LanguageId: 'en'
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            {params: queryParams});
    }

    /*
    TODO - remove FromDate and ToDate fields
     */
    public getPaymentRequests(filterOptions: any, paymentRequestType: number): Observable<any> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentRequestsPaging',
            RequestObject: {
                ...filterOptions,
                Type: paymentRequestType,
                filterModel: {}
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public loadPaymentRequestsStates(): void {
        if (localStorage.getItem('enum_payment-request-states')) {
            this.paymentRequestStates$.next(JSON.parse(localStorage.getItem('enum_payment-request-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetPaymentRequestStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = (status.Name === 'Approved'
                                || status.Name === 'Approved Manually'
                                || status.Name === 'Confirmed') ? '#79F2B8' :
                                (status.Name === 'Cancelled By Client'
                                    || status.Name === 'CancelledByUser'
                                    || status.Name === 'Failed') ? '#FFD6DA' :
                                    (status.Name === 'Pending'
                                        || status.Name === 'WaitingForKYC'
                                        || status.Name === 'Pay Pending'
                                        || status.Name === 'InProcess'
                                        || status.Name === 'CheckName'
                                        || status.Name === 'Frozen') ? '#E3E3E8' :
                                        status.Name === 'Deleted' ? '#EE6464' : '#E3E3E8';

                            status.Color = (status.Name === 'Approved'
                                || status.Name === 'Approved Manually'
                                || status.Name === 'Confirmed') ? '#3F3F3F' :
                                (status.Name === 'Cancelled By Client'
                                    || status.Name === 'CancelledByUser'
                                    || status.Name === 'Failed') ? '#3F3F3F' :
                                    (status.Name === 'Pending'
                                        || status.Name === 'WaitingForKYC'
                                        || status.Name === 'Pay Pending'
                                        || status.Name === 'InProcess'
                                        || status.Name === 'CheckName'
                                        || status.Name === 'Frozen') ? '#B3B3BC' :
                                        status.Name === 'Deleted' ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_payment-request-states', JSON.stringify(data.ResponseObject));
                        this.paymentRequestStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getPaymentRequestById(id: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentRequestById',
            RequestObject: id
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getPaymentRequestHistories(id: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentRequestHistories',
            RequestObject: { PaymentRequestId: id }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getObjectTranslations(req: { [key: string]: number | string }): Observable<IResponseData<IObjectTranslation>> {
        const payload = {
            Controller: 'Content',
            Method: 'GetObjectTranslations',
            RequestObject: req
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getPlayerQuickMetrics(playerId: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetClientQuickMetrics',
            RequestObject: playerId
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public updatePaymentRequestStatus(reqObj): Observable<IResponseData<any>> {

        let method = '';

        switch (reqObj.RequestType) {
            case StatusesEnum.CancelledByClient:
                method = 'CancelClientPaymentRequest';
                break;
            case StatusesEnum.InProcess:
                method = 'SetToInProcessPaymentRequest';
                break;
            case StatusesEnum.Frozen:
                method = 'SetToFrozenPaymentRequest';
                break;
            case StatusesEnum.WaitingForKYC:
                method = 'SetToWaitingForKYCPaymentRequest';
                break;
            case StatusesEnum.CancelledByUser:
                method = 'RejectPaymentRequest';
                break;
            case StatusesEnum.Confirmed:
                method = 'AllowPaymentRequest';
                break;
            case StatusesEnum.Approved:
            case StatusesEnum.ApprovedManually:
                method = 'PayPaymentRequest';
                break;
            case StatusesEnum.CheckName:
                method = 'SetToCheckNamePaymentRequest';
                break;
        }
        reqObj.Parameters = '{}';
        const payload = {
            Controller: 'PaymentSystem',
            Method: method,
            RequestObject: reqObj
        };
        if (!reqObj.inDepositsPage) {
            switch (reqObj.RequestType) {
                case StatusesEnum.Approved:
                case StatusesEnum.ApprovedManually:
                    payload.Method = 'PayWithdrawRequest';
                    break;
            }
        }
        delete reqObj.inDepositsPage;
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getNumberRoundingOption(params: ICellRendererParams): string {
        const points = (params.data.IsTotal || !params.data.IsTotal && !params.data.Points) ?
            this.authService.userData$.getValue()?.Points : params.data.Points;
        return `.${points}-${points}`;
    }

    public getPaymentSystems(): void {
        const payload = {
            Controller: 'PaymentSystem',
            Method: 'GetPaymentSystems',
            RequestObject: {}
        };
        this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload).pipe(
            tap(data => { this.paymentSystems$.next(data.ResponseObject?.sort((a, b) => {
                return a.Name.localeCompare(b.Name);
            })); })
        ).subscribe();
    }

}
