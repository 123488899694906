import {Component, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService, ConfigService, LanguageService } from '@core/services';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public isAuthenticated: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private authService: AuthService,
        private primengConfig: PrimeNGConfig,
        public languageService: LanguageService,
        private configService: ConfigService
    ) {
        this.languageService.init();

        this.configService.setRootColorVariables();
        this.configService.setSelectedTheme();
        if (localStorage.getItem('generalConfigs')) {
            this.configService.partnerConfigs = JSON.parse(localStorage.getItem('generalConfigs'));
        }
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.subscribeToAuthChanges();
    }

    private subscribeToAuthChanges(): void {
        this.authService.isAuthenticated$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
            this.isAuthenticated = val;
        });
    }
}
