import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private menuSource: Subject<string> = new Subject<string>();
    private resetSource: Subject<unknown> = new Subject();

    menuSource$: Observable<string> = this.menuSource.asObservable();
    resetSource$: Observable<unknown> = this.resetSource.asObservable();

    onMenuStateChange(key: string): void {
        this.menuSource.next(key);
    }

    reset(): void {
        this.resetSource.next(null);
    }
}
