import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { AuthService, HttpService } from '@core/services';
import {
    IAppConfig,
    IPartnerConfig,
    IPartnerConfigs,
    ISelectedTheme,
} from '@core/interfaces';
import { Methods } from '@core/enums';

import { initWelcomeScene, clearWelcomeScene } from 'src/assets/scripts/welcome-page-animation.js';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public config: IAppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    public menuMode = 'static';
    public sidebarScrollTop$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public sidebarSmallModeIsActiveSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public colorScheme = localStorage.getItem('selectedTheme') || 'light';
    public colorScheme$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public profModeActive: boolean;

    public menuTheme = 'layout-sidebar-darkgray';

    public inputStyle = 'outlined';

    public ripple: boolean = true;

    private configUpdate = new Subject<IAppConfig>();

    public configUpdate$ = this.configUpdate.asObservable();

    public configClick: boolean;

    public configActive: boolean;

    public selectedTheme: ISelectedTheme = {};

    public partnerConfigs: IPartnerConfigs[];

    public reCaptchaKey: string;

    public theme$ = new Subject<string>();

    public currencyCacheQuery: number = this.randomNumber;
    public flagCacheQuery: number = this.randomNumber;

    public isRtl: boolean = false;
    public showAgGridTable: boolean = true;

    constructor(
        private http: HttpService,
        private authService: AuthService,
        private router: Router,
    ) {
        this.changeColorScheme(this.colorScheme);
        this.isRtl = localStorage.getItem('direction') === 'rtl';
        this.setDOMLayoutDirection();
    }

    private setDOMLayoutDirection(): void {
        this.isRtl ? document.body.classList.add('layout-rtl') : document.body.classList.remove('layout-rtl')
    }

    get randomNumber(): number {
        return window.crypto.getRandomValues(new Uint32Array(1))[0];
    }

    public changeThemeSubject(theme: string): void {
        this.theme$.next(theme);
    }

    public onConfigClick(event): void {
        this.configClick = true;
    }


    public updateConfig(config: IAppConfig): void {
        this.config = config;
        this.configUpdate.next(config);
    }

    public getConfig(): IAppConfig {
        return this.config;
    }

    get getPartnerConfigsData(): IPartnerConfigs[] | null {
        return JSON.parse(localStorage.getItem('generalConfigs'));
    }

    public setRootColorVariables(): void {
        const root = document.documentElement;
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        this.colorScheme$.next(this.colorScheme);
        const selectedScheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'LightColors').Value :
            configs?.find(config => config.Key === 'DarkColors').Value;
        selectedScheme?.forEach(color => {
            root.style.setProperty(`--${color.Key}`, color.Value);
        });
    }

    public setSelectedTheme(): void {
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        this.colorScheme$.next(this.colorScheme);
        const selectedTheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'Light').Value :
            configs?.find(config => config.Key === 'Dark').Value;

        selectedTheme?.forEach(scheme => {
            this.selectedTheme[scheme.Key] = scheme.Value;
        });
    }

    private setProfMode(): void {
        if (!localStorage.getItem('profMode')) {
            localStorage.setItem('profMode', 'active');
            this.profModeActive = true;
        } else {
            this.profModeActive = localStorage.getItem('profMode') === 'active';
        }
    }

    public toggleProfMode(): void {
        localStorage.setItem('profMode', this.profModeActive ? 'active' : 'inactive');
    }

   public async getReCaptchaKey(): Promise<string> {
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
        }
        return JSON.parse(localStorage.getItem('generalConfigs'))?.find(config => config.Key === 'General')
            .Value?.find(config => config.Key === 'ReCaptchaKey')?.Value;
    }

    public async load(): Promise<void> {
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
            this.updateFavicon();
        } else {
            this.partnerConfigs = JSON.parse(localStorage.getItem('generalConfigs'));
            this.setRootColorVariables();
            this.setSelectedTheme();
            this.setProfMode();
            this.updateFavicon();
        }
    }

    public async getPartnerConfigs(): Promise<void> {
        return this.http.request('get', environment.ApiUrl + Methods.PARTNER_ADMIN_CONFIG)
            .pipe(map(response => response.ResponseObject))
            .toPromise()
            .then((data: IPartnerConfigs[]) => {
                localStorage.setItem('generalConfigs', JSON.stringify(data));
                this.partnerConfigs = data;
                this.setProfMode();
                this.setRootColorVariables();
                this.setSelectedTheme();
            });
    }

    private updateFavicon(): void {
        if ( this.partnerConfigs?.find(config => config.Key === 'Light')
            .Value?.find(c => c.Key === 'SecondaryShortLogo')?.Value ) {
            const favIcon: HTMLLinkElement = document.querySelector('#favIcon') as HTMLLinkElement;

            favIcon.href = this.partnerConfigs?.find(config => config.Key === 'Light')
                .Value?.find(c => c.Key === 'SecondaryShortLogo')?.Value;
        }

    }

    public changeColorScheme(scheme): void {
        if (scheme === 'light') {
            this.updateConfig({ ...this.config, ...{ dark: false } });
        } else {
            this.updateConfig({ ...this.config, ...{ dark: true } });
        }
        this.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.setRootColorVariables();
    }

    public changeTheme(e): void {
        e.originalEvent.stopPropagation();
        const scheme = e.checked ? 'dim' : 'light';

        if (scheme === 'light') {
            this.updateConfig({ ...this.config, ...{ dark: false } });
        } else {
            this.updateConfig({ ...this.config, ...{ dark: true } });
        }
        this.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.setRootColorVariables();
        this.changeThemeSubject(scheme);
    }

    private changeStyleSheetsColor(id, value, from): void {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {       // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    private replaceLink(linkElement, href): void {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
                this.handleThemeChangeInWelcomePage();
            });
        }
    }

    public changeDirection(isRtl: boolean): void {
        localStorage.setItem('direction', isRtl ? 'rtl' : 'ltr');
        this.setDOMLayoutDirection();
    }

    private isIE(): boolean {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    public loadExternalScript(src: string, type: string, id?: string, isAsync?: boolean, isDefer?: boolean): void {
        const externalScript: HTMLScriptElement = document.createElement('script');

        externalScript.src = src;
        externalScript.type = type;
        externalScript.id = id;
        externalScript.async = !!isAsync;
        externalScript.defer = !!isDefer;

        document.body.appendChild(externalScript);
    }

    private handleThemeChangeInWelcomePage(): void {
        if (this.router.url.includes('welcome') || this.router.url === '/') {
            if (!document.querySelector('.welcome-page-canvas')) {
                initWelcomeScene();
            } else {
                document.querySelector('.welcome-page-canvas').remove();
                clearWelcomeScene();
                initWelcomeScene();
            }
        }
    }

    public updateCurrencyCacheQuery(): void {
        this.currencyCacheQuery = this.randomNumber;
    }

    public updateFlagCacheQuery(): void {
        this.flagCacheQuery = this.randomNumber;
    }
}
