import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from '@core/utilities';

function patternValidator(value: any, pattern: string | RegExp): boolean {
    if (!pattern) {
        return null;
    }
    
    let regex: RegExp;
    let regexStr: string;
    
    if (typeof pattern === 'string') {
        regexStr = '';

        if (pattern.charAt(0) !== '^') {
            regexStr += '^';
        }

        regexStr += pattern;

        if (pattern.charAt(pattern.length - 1) !== '$') {
            regexStr += '$';
        }

        regex = new RegExp(regexStr);
    } else {
        regex = pattern;
    }

    if (isEmptyInputValue(value)) {
        return null;  // don't validate empty values to allow optional controls
    }

    return regex.test(value);
}

export function arrayPatternValidator(pattern: string | RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        for (const item of control.value) {
            if (!patternValidator(item, pattern)) {
                return { pattern: { requiredPattern: pattern.toString(), actualValue: item } };
            }
        }

        return null;
    };
}
