import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * @desc Custom validator function to ensure that at least one control from a given list has a value.
 * It checks if any of the provided control names in the group has a valid value (i.e., non-empty or non-null).
 * If at least one control has a value, it returns null, indicating no error. Otherwise, it returns an error object.
 *
 * @param {string[]} controlNames - List of control names to check for values.
 * @returns {ValidatorFn} - Returns a validator function that checks if at least one of the given controls has a value.
 */
export function atLeastOneRequiredValidator(controlNames: string[]): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        if (!controlNames.length) {
            return null;
        }

        const hasAtLeastOneValue = controlNames.some(name => {
            const control = group.get(name);
            const value = control?.value;

            return value && (!Array.isArray(value) || value.length > 0);
        });

        return hasAtLeastOneValue ? null : { atLeastOneRequired: true };
    };
}
