import { takeUntil } from 'rxjs/operators';

export function changeManualDetailTheme(parentElement, configService): void {
    configService.theme$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(subjectData => {
            const localTheme = localStorage.getItem('selectedTheme');
            const elements = parentElement.nativeElement.querySelectorAll('.ag-details-grid');
            if (subjectData && localTheme === subjectData) {
                elements.forEach(elem => {
                    if (elem && subjectData === 'dim'
                        && elem?.classList.contains('ag-theme-alpine')) {
                        elem.classList.remove('ag-theme-alpine');
                        elem.classList.add('ag-theme-alpine-dark');
                    } else if (elem && subjectData === 'light'
                        && elem?.classList.contains('ag-theme-alpine-dark')) {
                        elem.classList.remove('ag-theme-alpine-dark');
                        elem.classList.add('ag-theme-alpine');
                    }
                });
            }
        });
}
