<div class="layout-ltr">
    <ng-container *ngIf="viewMode; else timeZoneEditBlock">
        <span>{{ now | date:'HH:mm:ss' : formattedTimeZoneOffset }}</span>&nbsp;
        <span>{{ 'GMT ' + formattedTimeZoneOffset }}</span>
    </ng-container>

    <ng-template #timeZoneEditBlock>
        <div class="time-zone-container">
            <div class="time-zone-text">
                <span>{{ now | date:'HH:mm:ss' : formattedTimeZoneOffset }}</span>
                <span>{{ ' ' + formattedTimeZoneOffset }}</span>
            </div>
            <div class="GMT-buttons cursor-pointer">
                <div class="arrow" (click)="GMTPlusMinus('plus')">
                    <i class="pi icon icon-arrow_up_filled icon-size-md"></i>
                </div>
                <p-divider layout="horizontal"></p-divider>
                <div class="arrow" (click)="GMTPlusMinus('minus')">
                    <i class="pi icon icon-arrow_down_filled icon-size-md"></i>
                </div>
            </div>
        </div>
    </ng-template>

</div>
