/** @desc Current function is taken from our Sportsbook project, and is responsible for creation of sport icons */
export function generateSportTypeIcon(sportType: string): string {
    switch (sportType) {
        case "Ice Hockey":
            return "Hockey-Icon";
        case "Table Tennis":
            return "Table-Tennis-Icon";
        case "American Football":
            return "American-Football-Icon";
        case "Rugby":
            return "Rugby-league-Icon";
        case "Futsal":
            return "Futsall-Icon";
        case "Boxing":
            return "Box-Icon";
        case "Gaelic Football":
        case "Gaelic sports":
            return "Gaelic-football-Icon";
        case "Gaelic Hurling":
            return "Gaelic-Hurling-Icon";
        case "Waterpolo":
            return "Water-polo-Icon";
        case "Aussie Rules":
            return "Australian-Rules-Icon";
        case "Beach Volley":
            return "Beach-Volleyball-Icon";
        case "League of Legends":
            return "League-of-Legends-Icon";
        case "Field hockey":
            return "Field-Hockey-Icon";
        case "Horse racing":
            return "Horse-racing-Icon";
        case "E-Games":
            return "E-Sports-Icon";
        case "Rugby League":
            return "Rugby-league-Icon";
        case "Formula 1":
            return "Formula-1-Icon";
        case "Pesapallo":
            return "Pasepallo-Icon";
        case "Beach Soccer":
            return "Beach-Soccer-iconn";
        case "TV-Games":
            return "TV-Icon";
        case "Padel":
            return "Paddel-Tennis-Icon";
        case "Ski Jumping":
            return "Ski-jumping-Icon";
        case "Nascar Cup Series":
        case "Stock Car Racing":
            return "Nascar-Icon";
        case "Motorbikes":
        case "Motorcycle Racing":
            return "Motocycling-Icon";
        case "Basketball 3x3":
            return "Basketball-Icon";
        case "Alpine Skiing":
            return "Alpine-skiing-Icon";
        case "NetBallShots":
            return "NetBallShots";
        default:
            return `${sportType}-Icon`;
    }
}
