<ng-container>
    <div *ngIf="root && item.visible !== false && item.label"
         class="layout-menuitem-root-text"
    >
        {{item.label | translate}}
    </div>
    <a [attr.href]="item.url"
       (click)="itemClick($event)"
       *ngIf="(!item.routerLink || item.items) && item.visible !== false"
       (mouseenter)="onMouseEnter()"
       (keydown.enter)="itemClick($event)"
       [ngClass]="item.class"
       pRipple
       [attr.target]="item.target"
       [attr.tabindex]="0"
    >
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">
            {{ item.label | translate }}
        </span>
        <ng-container *ngIf="item.secondaryIcon">
            <div class="layout-menuitem-secondary-container"
                 (click)="secondaryItemClick($event)"
                 [routerLink]="item.secondaryRouterLink"
            >
                <i [ngClass]="item.secondaryIcon" class="layout-menuitem-secondary-icon"></i>
            </div>
        </ng-container>
        <i class="pi pi-fw layout-submenu-toggler icon-size-sm"
           [ngClass]="{'icon-arrow_down': !appMain.isHorizontal(), 'icon-arrow_up': appMain.isHorizontal()}"
           *ngIf="item.items"></i>
    </a>
    <a (click)="itemClick($event)"
       (mouseenter)="onMouseEnter()"
       *ngIf="(item.routerLink && !item.items) && item.visible !== false"
       [pTooltip]="item.label | translate"
       [tooltipDisabled]="!appMain.staticMenuDesktopInactive || !item.icon"
       [routerLink]="item.routerLink"
       routerLinkActive="active-route"
       [ngClass]="item.class"
       pRipple
       [routerLinkActiveOptions]="{exact: !item.preventExact}"
       [attr.target]="item.target"
       [attr.tabindex]="0"
    >
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">
            {{ item.label | translate }}
        </span>
        <i class="pi pi-fw layout-submenu-toggler icon-size-sm"
           [ngClass]="!appMain.isHorizontal() ?'icon-arrow_down': 'icon-arrow_up'"
           *ngIf="item.items"
        ></i>
    </a>

    <ul *ngIf="item.items && item.visible !== false"
        role="menu"
        [@children]="(appMain.isSlim() || appMain.isHorizontal()) ? (root ? appMain.isMobile()? 'visible':
			slimClick && !appMain.isHorizontal() ? (active  ? 'slimVisibleAnimated' : 'slimHiddenAnimated') : (active ? 'visible' : 'hidden') :
			appMain.isSlim() || appMain.isHorizontal() ? (active ? 'visibleAnimated' : 'hiddenAnimated') : (active ? 'visible' : 'hidden')) :
			(root ? 'visible' :(active ? 'visibleAnimated' : 'hiddenAnimated'))"
    >
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <ng-container *ngIf="child.level === 1; else noCustomPositionBlock">
                <li app-menuitem
                    subMenuPosition
                    [item]="child"
                    [index]="i"
                    [parentKey]="key"
                    [class]="child.badgeClass"
                >
                </li>
            </ng-container>

            <ng-template #noCustomPositionBlock>
                <li app-menuitem
                    [item]="child"
                    [index]="i"
                    [parentKey]="key"
                    [class]="child.badgeClass"
                >
                </li>
            </ng-template>
        </ng-template>
    </ul>
</ng-container>
