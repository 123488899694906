<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi icon icon-menu icon-size-xl"></i>
        </a>
        <span class="topbar-separator"></span>

        <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>

        <img
            id="logo-mobile"
            class="mobile-logo"
            [src]="configService.colorScheme === 'light' ?
             configService.selectedTheme?.SecondaryShortLogo : configService.selectedTheme?.PrimaryShortLogo"
            alt="logo"/>
    </div>

    <app-menu></app-menu>

    <div class="layout-mask modal-in"></div>


    <div class="topbar-right">
        <ul class="topbar-menu" *ngIf="authService.userData$ | async as user">
            <!--            <li class="search-item">-->
            <!--                <a tabindex="0" (click)="appMain.onSearchClick($event)">-->
            <!--                    <i class="pi icon icon-search"></i>-->
            <!--                </a>-->
            <!--            </li>-->
            <!--            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">-->
            <!--                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">-->
            <!--                    <i class="pi icon icon-notifications icon-size-md"></i>-->
            <!--                    <span class="topbar-badge">5</span>-->
            <!--                </a>-->
            <!--                <ul class="notifications-menu fade-in-up">-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-shopping-cart"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">New Order</div>-->
            <!--                                <div class="notification-detail">You have <strong>3</strong> new orders.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-check-square"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Withdrawn Completed</div>-->
            <!--                                <div class="notification-detail">Funds are on their way.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-chart-line"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Monthly Reports</div>-->
            <!--                                <div class="notification-detail">New reports are ready.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-comments"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Comments</div>-->
            <!--                                <div class="notification-detail"><strong>2</strong> new comments.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-exclamation-circle"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Chargeback Request</div>-->
            <!--                                <div class="notification-detail"><strong>1</strong> to review.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </li>-->

            <li class="profile-item"
                [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <div class="profile-info profile-info-desktop-view">
                        <span class="profile-name">{{user.UserLogin}}</span>
                        <div class="profile-detail">
                            <app-time-zone-container [viewMode]="true"
                                                     [timeZoneOffset]="user?.TimeZone">
                            </app-time-zone-container>
                            <span class="seperator"></span>
                            <span>{{user?.CurrencyId}}</span>
                        </div>
                    </div>
                    <i class="pi icon icon-user icon-size-lg"></i>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li class="custom-menu-item profile-menu-li profile-info profile-info-mobile-view">
                        <span class="profile-name">{{ user.UserLogin }}</span>
                        <div class="profile-detail">
                            <app-time-zone-container [viewMode]="true"
                                                     [timeZoneOffset]="user?.TimeZone">
                            </app-time-zone-container>
                            <span class="seperator"></span>
                            <span>{{user?.CurrencyId}}</span>
                        </div>
                    </li>
                    <li class="profile-menu-li">
                        <a routerLink="profile">
                            <i class="pi icon icon-user_outline icon-size-sm"></i>
                            <span class="profile-menu-item">{{'Profile' | translate}}</span>
                        </a>
                    </li>
                    <li (click)="$event.stopPropagation()" class="switcher-section profile-menu-li">
                        <a class="switcher">
                            <div class="flex align-items-center">
                                <i class="pi icon-moon icon-size-sm"></i>
                                <span class="profile-menu-item">{{'DarkMode' | translate}}</span>
                            </div>

                            <p-inputSwitch
                                [(ngModel)]="darkModeActive"
                                (onChange)="configService.changeTheme($event)"
                            ></p-inputSwitch>
                        </a>
                    </li>

                    <li (click)="$event.stopPropagation()" class="switcher-section profile-menu-li prof-mode-li">
                        <a class="switcher">
                            <div class="flex align-items-center">
                                <i class="pi icon-eye_opened icon-size-sm"></i>
                                <span class="profile-menu-item">{{'ProfView' | translate}}</span>
                            </div>

                            <p-inputSwitch
                                [(ngModel)]="configService.profModeActive"
                                (onChange)="configService.toggleProfMode()"
                            ></p-inputSwitch>
                        </a>
                    </li>

                    <li class="dropdown-section profile-menu-li">
                        <p-panelMenu class="profile-menu" styleClass="languages" [model]="panelMenuItems"></p-panelMenu>
                    </li>

                    <li *ngIf="authService.isAuthenticated$ | async" (click)="authService.logout()">
                        <a>
                            <i class="pi icon icon-logout icon-size-sm"></i>
                            <span class="profile-menu-item">{{'Logout' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="right-sidebar-item"
                aria-disabled="true">
                <a tabindex="0" (click)="appMain.onRightMenuClick($event)">
                    <i class="pi icon icon-fast_search icon-size-lg"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
