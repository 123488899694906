import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { IDeviceDetection, ILoginForm, IUser } from '@core/interfaces';
import { environment } from '@env/environment';
import { encryptData } from '@core/utils';
import { Methods } from '@core/enums';
import { HttpService } from '@core/services/http.service';
import { timeZoneFormatter } from '@core/utilities';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public userData$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!this.tokenGetter());

    constructor(
        private http: HttpService,
        private cookieService: CookieService,
        private router: Router,
    ) {
        if (this.cookieService.get('token')) {
            this.isAuthenticated$.next(!!this.cookieService.get('token'));
        }
        if (this.cookieService.get('user')) {
            this.userData$.next(JSON.parse(this.cookieService.get('user')) ? JSON.parse(this.cookieService.get('user')) : null);
        }
    }

    public tokenGetter(): string | null {
        return this.cookieService.get('token');
    }

    public userGetter(): IUser | null {
        return (this.cookieService.get('user') && JSON.parse(this.cookieService.get('user'))) || null;
    }

    public get timezoneGetter(): string {
        const user = JSON.parse(this.cookieService.get('user'));
        return timeZoneFormatter(user.TimeZone);
    }

    public storageRequiredFieldsExist(): boolean {
        return this.isAuthenticated$.getValue() && !!this.userGetter();
    }

    public isAuthenticated(): boolean {
        return !!this.tokenGetter() && !!this.userGetter();
    }

    public login(params: ILoginForm, ReCaptcha?: string, DeviceDetectionApiData?: IDeviceDetection): Observable<any> {
        const loginData = {
            data: encryptData(params),
            ReCaptcha,
            ...DeviceDetectionApiData
        };
        return this.http.request('post', environment.ApiUrl + Methods.LOGIN_USER, loginData);

    }

    public logout(): void {
        this.cookieService.delete('token');
        this.cookieService.delete('user');
        localStorage.removeItem('enum_partners');

        for (let i: number = 0; i < localStorage.length; i++) {
            const key: string = localStorage.key(i);
            if (key && key.startsWith('filters')) {
                    localStorage.removeItem(key);
            }
        }

        this.isAuthenticated$.next(false);
        this.userData$.next(null);
        this.router.navigate(['/login']);
    }

    public healthCheck(): Observable<any> {
        return this.http.request('get', environment.ApiUrl + Methods.HEALTH_CHECK);
    }

}
