import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateFn,
    CanActivateChildFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services';

const canActivateHandler = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean> | boolean => {
    const authService = inject(AuthService);
    if (state?.url !== '/login' && !authService.isAuthenticated()) {
        authService.logout();
        return false;
    }
    return true;
};

export const canActivateAuthGuard: CanActivateFn = canActivateHandler;
export const canActivateChildAuthGuard: CanActivateChildFn = canActivateHandler;
