import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

import { Methods } from '@core/enums';
import {
    IResponseWithCountNew,
    INote,
    INoteHistory,
    IResponseData,
    INoteCategory,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class NotesService {

    constructor(
        private http: HttpService
    ) {}

    public noteCategories$: BehaviorSubject<INoteCategory[]> = new BehaviorSubject<INoteCategory[]>([]);
    public sendNoteCountInfo$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public saveNote(note: INote): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'SaveNote',
            RequestObject: note
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public getNotes(data): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'GetNotes',
            RequestObject: data
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public saveNoteCategory(noteCategory: INoteCategory): Observable<any> {
        const payload = {
            Controller: 'Util',
            Method: 'SaveNoteCategory',
            RequestObject: noteCategory
        }

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload
        );
    }

    public getPartnerNoteCategories(data): Observable<IResponseData<INoteCategory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'GetNoteCategories',
            RequestObject: data
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload
        );
    }

    public getNoteHistory(noteId: number): Observable<IResponseData<INoteHistory[]>> {
        const payload = {
            Controller: 'Util',
            Method: 'GetNoteHistory',
            RequestObject: noteId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public saveOrUpdateComment(payload: any): Observable<IResponseWithCountNew<INoteHistory>> {
        return this.http.request(
            'post',
            environment.BaseApiUrl + Methods.UPDATE_DEVICE_COMMENT,
            payload
        );
    }

    public updateNoteCategoryStatus(data): Observable<any> {
        const payload: any = {
            Controller: 'Util',
            Method: 'UpdateNoteCategoryStatus',
            RequestObject: data
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
        );
    }
}
