import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minimumAgeValidator(age: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const birthDate = new Date(control.value);
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - age);
        if (birthDate > eighteenYearsAgo) {
            return { minimumAge: true };
        }
        return null;
    };
}
