import { Component, OnDestroy, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService, ConfigService } from '@core/services';

@Component({
    selector: 'app-maintenance',
    templateUrl: './app.maintenance.html',
    styleUrls: ['./app.maintenance.scss']
})
export class AppMaintenanceComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public configService: ConfigService,
        private authService: AuthService
    ) {

    }

    ngOnInit(): void {
        this.authService.healthCheck()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
