export enum StatusesEnum {
    Pending = 1,
    CancelledByClient = 2,
    InProcess = 3,
    Frozen = 4,
    WaitingForKYC = 5,
    CancelledByUser = 6,
    Confirmed = 7,
    Approved = 8,
    Failed = 9,
    PayPending = 10,
    Deleted = 11,
    ApprovedManually = 12,
    CheckName = 13
}
