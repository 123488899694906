import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService, CommonService } from '@core/services';
import { IPartner } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private breadcrumbPartnerName: string;
    private breadcrumbItemsSource$ = new BehaviorSubject<MenuItem[]>([]);
    public breadcrumbItems$ = this.breadcrumbItemsSource$.asObservable();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private commonService: CommonService,
    ) {
        combineLatest([
            this.commonService.partners$,
            this.languageService.translationLoaded$,
            this.router.events.pipe(
                /** @desc Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end */
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router),
            )
        ])
        .subscribe({
            next: () => this.createBreadcrumbs(this.route.root.snapshot),
        })
    }

    /**
     * @desc sets breadcrumbs with recursion starting from root and ending with activated route.
     * @param {ActivatedRouteSnapshot} snapshot - current route snapshot
     * @param {string} partnerName - selected partner name 
     * ( getting from route params or assigning manually )
     * @param {string[]} parentUrl - current route url plus it's parent route url 
     * ( parent url is set every time in recursion )
     * @param {MenuItem[]} breadcrumbItems - current breadcrumb items plus parent breadcrumb items
     * ( parent breadcrumb items are set every time in recursion )
     */
    private createBreadcrumbs(
        snapshot: ActivatedRouteSnapshot,
        partnerName: string = null,
        parentUrl: string[] = [],
        breadcrumbItems: MenuItem[] = [],
    ): void {
        if (snapshot) {
            const routeUrl = parentUrl.concat(snapshot.url.map(url => url.path));

            if (snapshot.data.breadcrumb) {
                const breadcrumbTitle: string = snapshot.routeConfig.data?.breadcrumb?.title;
                const breadcrumbNavigate: boolean = snapshot.routeConfig.data?.breadcrumb?.navigate;
                const hasPartnerName: boolean = snapshot.routeConfig.data?.breadcrumb?.hasPartnerName;

                partnerName ??= this.breadcrumbPartnerName || this.commonService.partners$.getValue()
                    ?.find((item: IPartner) => item.Id === +snapshot.params.id)?.Name;

                if (breadcrumbTitle) {
                    const translatedTitle = this.translateService.instant(breadcrumbTitle);

                    breadcrumbItems.push( {
                        label: hasPartnerName && partnerName
                            ? partnerName + ': ' + translatedTitle
                            : translatedTitle,
                        routerLink: `/${routeUrl.join('/')}`,
                        styleClass: breadcrumbNavigate ? '' : 'pointer-events-none',
                    });
                }

                this.breadcrumbItemsSource$.next(breadcrumbItems);
            }

            this.createBreadcrumbs(snapshot.firstChild, partnerName, routeUrl, breadcrumbItems);
        }
    }

    /**
     * @desc sets title and partner name for current route breadcrumb 
     * and starts new recursion of setting breadcrumbs from root.
     * @param {ActivatedRouteSnapshot} routeSnapshot - current route snapshot
     * @param {string} title - passed title to be set in route data of current route.
     * ( setting title modifies route object so we need to set it null to reset when entering the page or leaving it )
     * @param {string} partnerName - optional argument for setting partnerName manually 
     * and not finding it in partners list
     */
    public setBreadcrumb(
        routeSnapshot: ActivatedRouteSnapshot,
        title: string,
        partnerName?: string,
    ): void {
        routeSnapshot.data.breadcrumb.title = title;
        this.breadcrumbPartnerName = partnerName;
        this.createBreadcrumbs(this.route.root.snapshot, partnerName);
    }
}
