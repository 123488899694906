import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-step',
    templateUrl: './step-component.component.html',
    styleUrls: ['./step-component.component.scss']
})
export class StepComponentComponent implements OnInit, OnDestroy {

    @Input() stepsItem: MenuItem[];
    @Input() stepFormGroups: Array<FormGroup | FormArray>;
    @Output() activeStepIndexChange = new EventEmitter<number>();

    private activeStepLocalIndex: number = 0;
    private isStepDisabled = {
        VALID: false,
        INVALID: true
    };
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
    ) {
    }

    /**
     * @desc Setter for the `activeStepIndex` property. When provided with a new value,
     * checks for a difference from the current local active step index. If they're different,
     * it updates the local index and emits an event with the updated value.
     * @param {number} value - The new value intended for the active step index.
     */
    @Input() set activeStepIndex(value: number) {
        if (this.activeStepLocalIndex !== value) {
            this.activeStepLocalIndex = value;
            this.activeStepIndexChange.emit(this.activeStepLocalIndex);
        }
    }

    get activeStepIndex(): number {
        return this.activeStepLocalIndex;
    }

    ngOnInit(): void {
        this.setStepItems();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setStepItems(): void {
        this.stepFormGroups.forEach((form, index) => {
            this.subscribeToFormStatusChange(form, index);
        });
    }

    /**
     * @desc Configures the step items based on the active index and whether they should be disabled.
     * This method will mark steps as disabled based on the condition provided and will also set
     * the style class for the steps based on their validation status. The method modifies the
     * `stepsItem` property in-place.
     * @param {number} activeIndex - The index of the currently active step.
     * @param {boolean} isDisabled - A boolean indicating if the steps should be disabled.
     */
    private setStepItemsConfiguration(activeIndex: number, isDisabled: boolean): void {
        this.stepsItem = this.stepsItem.map((step, index) => {
            if (
                index > activeIndex &&
                ((!isDisabled && this.stepFormGroups[index - 1]?.status === 'VALID') || isDisabled)
            ) {
                step.disabled = isDisabled;
            }

            step.styleClass = this.stepFormGroups[index]?.status === 'VALID' ? 'p-steps-item-completed' : '';

            return step;
        });
    }

    /**
     * @desc Subscribes to the status changes of a given form or form array. When the status
     * changes, it updates the configuration of step items using the `setStepItemsConfiguration`
     * method. The subscription will be terminated when the `unsubscribe$` observable emits.
     * @param {FormGroup | FormArray} form - The form or form array whose status changes need to be observed.
     * @param {number} activeIndex - The index of the currently active step.
     * @param {string} [formStatus] - The initial status of the form. Defaults to the current form status.
     */
    private subscribeToFormStatusChange(form: FormGroup | FormArray, activeIndex: number, formStatus?: string): void {
        form?.statusChanges
            .pipe(
                startWith(formStatus ?? form.status),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: (status) => {
                    this.setStepItemsConfiguration(activeIndex, this.isStepDisabled[status]);
                }
            });
    }


}
