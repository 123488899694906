import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { IGameProvider } from '@core/interfaces';

export function dateFormatter(params): string {
    const date = params.value ? new Date(params.value) : new Date(params);

    if (params.value === undefined) {
        return '';
    }

    return (
        (params.value !== null) ?
            ([
            String(date.getDate()).padStart(2, '0'),
            String(date.getMonth() + 1).padStart(2, '0'),
            date.getFullYear(),
        ].join('.') +
        ' ' +
        [
            String(date.getHours()).padStart(2, '0'),
            String(date.getMinutes()).padStart(2, '0'),
            String(date.getSeconds()).padStart(2, '0'),
        ].join(':')) : '-'
    );
}

export function dataFormatter(data, params): string {
    return data.getValue().find(st => st.Id === +params.value)?.Name;
}

export function dataFormatterById(data, translate, params): string {
    return translate
        ? this.translateService.instant(data.getValue().find(st => st.Id === +params.value)?.Name || ' ')
        : data.getValue().find(st => st.Id === +params.value)?.Name;
}

export function dataFormatterByValue({ data, translate, parentTranslationKey }, params): string {
    if (translate) {
        if (parentTranslationKey) {
            return this.translateService.instant((parentTranslationKey + '.' + data.getValue().find(st =>
                st.Value === (Number.isNaN(+params.value) ? params.value : +params.value))?.Name || ' '));
        }
        return this.translateService.instant(data.getValue().find(st =>
            st.Value === (Number.isNaN(+params.value) ? params.value : +params.value))?.Name || ' ');
    }
    return data.getValue().find(st => st.Value === +params.value)?.Name;
}

export function filterFormatter(data, field, params): string {
    return data.getValue().find(st => st.Id === params.data[field])?.Name;
}

export function dataBooleanFormatter(data, params): string {
    return data.getValue().find(st => st.Value === (params.value === 'true'))?.Name;
}

export function languageFormatter(data, params): string {
    return data.getValue().languages.find(st => st.Id === params.value)?.Name;
}

export function transformFilter(gameProviders$: BehaviorSubject<IGameProvider[]>, translateService: TranslateService, params): string {
    return translateService.instant(params.value);
}

export function decimalFormatter(points, params): string {
    if ( isNaN(params.value) ) {
        return null;
    }
    const decimalVal = new Intl.NumberFormat('en', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: points,
        maximumFractionDigits: 20
    }).format(params.value);

    const strVal = decimalVal.toString();
    if (strVal.indexOf('.') === -1) {
        return decimalVal;
    }
    return strVal.slice(0, (strVal.indexOf('.')) + (points ? (points + 1) : 0));
}

export function decimalFormatterByPoints(points, value): string {
    if ( isNaN(value) ) {
        return null;
    }
    const decimalVal = new Intl.NumberFormat('en', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: points,
        maximumFractionDigits: 20
    }).format(value);

    const strVal = decimalVal.toString();
    if (strVal.indexOf('.') === -1) {
        return decimalVal;
    }
    return strVal.slice(0, (strVal.indexOf('.')) + (points ? (points + 1) : 0));
}
