import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ICommentTemplate, IResponseData } from '@core/interfaces';
import { Methods } from '@core/enums';
import { HttpService } from '@core/services';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class CommentTypesService {

    constructor(private http: HttpService) {
    }

    public getCommentTemplates(reqObj?: any): Observable<any> {
        const payload: any = {
            Controller: 'Content',
            Method: 'GetCommentTemplates',
            RequestObject: reqObj || {}
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public saveCommentTemplate(reqObj: any): Observable<IResponseData<ICommentTemplate[]>> {
        const payload: any = {
            Controller: 'Content',
            Method: 'SaveCommentTemplate',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
            false,
            null,
            true
        );
    }

    public editCommentTemplate(reqObj: any): Observable<IResponseData<ICommentTemplate[]>> {
        const payload: any = {
            Controller: 'Content',
            Method: 'EditCommentTemplate',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
        );
    }

    public deleteCommentTemplate(Id: number): Observable<IResponseData<ICommentTemplate[]>> {
        const payload: any = {
            Controller: 'Content',
            Method: 'DeleteCommentTemplate',
            RequestObject: Id
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload,
        );
    }
}
