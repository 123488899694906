import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppMainComponent } from '@core/components';
import { ConfigService } from '@core/services';
import { IMenuItems } from '@core/interfaces';
import { environment } from '@env/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    public model$: Observable<IMenuItems[]>;
    private model: IMenuItems[];

    constructor(
        public appMain: AppMainComponent,
        public configService: ConfigService,
    ) {

    }

    ngOnInit(): void {

        this.model = [
            {
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw icon-dashboard icon-size-sm', routerLink: ['/'] },
                    // {label: 'Bet Monitor', icon: 'pi pi-fw icon-bet_monitor', routerLink: ['/betmonitor']},
                    // {label: 'Real Time', icon: 'pi pi-fw icon-real_time', routerLink: ['/realtime']},
                    {
                        label: 'Payments',
                        icon: 'pi pi-fw icon-payments icon-size-sm',
                        level: 1,
                        items: [
                            { label: 'Deposits', routerLink: ['/payments', 'deposits'] },
                            { label: 'Withdrawals', routerLink: ['/payments', 'withdrawals'] },
                        ]
                    },
                    { label: 'Bets', icon: 'pi pi-fw icon-bets icon-size-sm', routerLink: ['/bets'] },
                    { label: 'LoyaltyProgram', icon: 'pi pi-fw icon-loyalty-program icon-size-sm', routerLink: ['/loyalty-program'] },
                    // {
                    //     label: 'Bonuses',
                    //     icon: 'pi pi-fw icon-bonuses',
                    //     items: [
                    //         {label: 'Common', routerLink: ['/bonuses/common']},
                    //         {label: 'Free Spins', routerLink: ['/bonuses/free-spins']},
                    //         {label: 'Triggers', routerLink: ['/bonuses/triggers']},
                    //     ]
                    // },
                    { label: 'Players', icon: 'pi pi-fw icon-user icon-size-sm', routerLink: ['/players'] },
                    // {
                    //     label: 'Player Categories',
                    //     icon: 'pi pi-fw icon-clients_categories icon-size-sm',
                    //     routerLink: ['/playercategories']
                    // },
                    { label: 'Partners', icon: 'pi pi-fw icon-partners icon-size-sm', routerLink: ['/partners'] },
                    { label: 'Users', icon: 'pi pi-fw icon-users icon-size-sm', routerLink: ['/users'] },
                    { label: 'RiskManagement', icon: 'pi pi-fw icon-risk_management icon-size-sm', routerLink: ['/risk-management'] },
                    {
                        label: 'Providers',
                        icon: 'pi pi-fw icon-providers icon-size-sm',
                        routerLink: ['/providers']
                    },
                    {
                        label: 'ProductGroups',
                        icon: 'pi pi-fw icon-product_groups icon-size-sm',
                        routerLink: ['/product-groups']
                    },
                    {
                        label: 'Products',
                        icon: 'pi pi-fw icon-products icon-size-sm',
                        routerLink: ['/products']
                    },
                    {
                        label: 'Reports',
                        icon: 'pi pi-fw icon-reports icon-size-sm',
                        level: 1,
                        items: [
                            { label: 'ReportByPartners', routerLink: ['/reports', 'report-by-partners'] },
                            { label: 'ReportByProviders', routerLink: ['/reports', 'report-by-providers'] },
                            { label: 'ReportByBonuses', routerLink: ['/reports', 'report-by-bonuses'] },
                            { label: 'ReportByCommissions', routerLink: ['/reports', 'report-by-commissions'] },
                            { label: 'ReportByGames', routerLink: ['/reports', 'report-by-games'] },
                            { label: 'MultiAccountDetection', routerLink: ['/reports', 'multi-account-detection'] },
                            { label: 'MADIdentificationCount', routerLink: ['/reports', 'multi-account-identification-count'] },
                        ]
                    },
                    {
                        label: 'KYCDocuments',
                        icon: 'pi pi-fw icon-file icon-size-sm',
                        routerLink: ['/kyc-documents']
                    },
                    {
                        label: 'Currencies',
                        icon: 'pi pi-fw icon-currencies icon-size-sm',
                        routerLink: ['/currencies']
                    },
                    {
                        label: 'Notifications',
                        icon: 'pi pi-fw icon-notifications icon-size-sm',
                        level: 1,
                        items: [
                            { label: 'Emails', routerLink: ['/notifications', 'emails'] },
                            { label: 'SMS', routerLink: ['/notifications', 'sms'] },
                            { label: 'Calls', routerLink: ['/notifications', 'calls'] },
                        ]
                    },
                    {
                        label: 'CMS',
                        level: 1,
                        icon: 'pi pi-fw icon-cms icon-size-sm',
                        items: [
                            { label: 'WebsiteContent', routerLink: ['/cms', 'website-content'] },
                            { label: 'WebsiteConfiguration', routerLink: ['/cms', 'website-configuration'] },
                            { label: 'WebsiteSEO', routerLink: ['/cms', 'website-seo'] },
                            { label: 'Backoffice', routerLink: ['/cms', 'backoffice'] },
                            { label: 'UserGuide', routerLink: ['/cms', 'user-guide'] },
                            { label: 'CommentTypes', routerLink: ['/cms', 'comment-types'] },
                        ]
                    },
                    // {label: 'Roles', icon: 'pi pi-fw icon-roles icon-size-sm', routerLink: ['/roles']},
                    // {
                    //     label: 'CRM',
                    //     icon: 'pi pi-fw icon-CRM icon-size-sm',
                    //     items: [
                    //         {label: 'Settings', routerLink: ['/crm/settings']},
                    //         {label: 'Templates', routerLink: ['/notifications/templates']},
                    //     ]
                    // },
                ]
            },
        ];

        this.model$ = of(this.model);
    }

    public onScrollSidebar(event): void {
        if (this.appMain.isDesktop()) {
            this.configService.sidebarScrollTop$.next(event.target.scrollTop);
        }
    }
}
