const scene = new THREE.Scene();
const renderer = new THREE.WebGLRenderer();

export function initWelcomeScene() {
    const root = document.documentElement;
    const CanvasBackgroundColor = getComputedStyle(root).getPropertyValue('--c-surface-100');
    const CanvasLinesColor = getComputedStyle(root).getPropertyValue('--c-surface-300');

    const camera = new THREE.PerspectiveCamera(95, window.innerWidth / window.innerHeight, 0.1, 20);

    THREE.PointerLockControls = function (camera) {
        const scope = this;
        camera.rotation.set(0, 0, 0);
        const pitchObject = new THREE.Object3D();
        pitchObject.add(camera);
        const yawObject = new THREE.Object3D();
        yawObject.position.y = 10;
        yawObject.add(pitchObject);
        const PI_2 = Math.PI / 2;
        const onMouseMove = function (event) {
            if (scope.enabled === false) return;
            let movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
            let movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
            yawObject.rotation.y -= movementX * 0.002;
            pitchObject.rotation.x -= movementY * 0.002;
            pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
        };
        document.addEventListener('mousemove', onMouseMove, false);
        this.enabled = false;
        this.getObject = function () {
            return yawObject;
        };
    };

    renderer.setSize(
        document.querySelector('.welcome-page-wrapper')?.clientWidth || 0,
        document.querySelector('.layout-content')?.clientHeight - 65 || 0
    );
    renderer.domElement.classList.add('welcome-page-canvas');
    setCanvasSize();
    setTimeout(() => {
        document.querySelector('.welcome-page-wrapper')?.appendChild(renderer.domElement);
    }, 0);

    const controls = new THREE.PointerLockControls(camera);
    controls.enabled = true;
    scene.add(controls.getObject());
    controls.getObject().position.set(0, 0, 0);

    const geometry = new THREE.BoxGeometry(10, 10, 10, 12, 12, 12);

    let material;
    initCanvasTheme();

    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(
            document.querySelector('.welcome-page-wrapper')?.clientWidth || 0,
            document.querySelector('.layout-content')?.clientHeight - 65 || 0
        );
        setCanvasSize();
    }

    window.addEventListener('resize', onWindowResize, false);

    function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
    }

    animate();

    function initCanvasTheme() {
        scene.background = new THREE.Color(CanvasBackgroundColor);
        material = new THREE.MeshBasicMaterial({
            color: CanvasLinesColor,
            wireframe: true
        });
    }

    function setCanvasSize() {
        renderer.domElement.style.width = '100%';
        renderer.domElement.style.height = '100%';
    }
}

export function clearWelcomeScene() {
    renderer.clear();
    scene.remove();
}
