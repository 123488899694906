export enum CorrectionActionType {
    Debit = 'Debit',
    Credit = 'Credit',
}

export enum CorrectionAccountType {
    UsedBalance = 2,
    BookingBalance = 3,
    BonusBalance = 12
}
