export enum NoteTypeEnum {
    Standard = 1,
    Player = 2,
    Bets = 12,
    Payments = 15,
    KYC = 57,
    Calls = 85,
    BlackList = 88,
    Partner = 89,
    PartnerApiUrl = 90
}

export enum NoteCategoriesEnum {
    System = 1
}
