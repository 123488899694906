import { Component, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { CommonService, ToastService } from '@core/services';
import { AppMainComponent } from '@core/components';
import { OnlyWhitespaceValidator } from '@core/validators';
import { RegexesEnum } from '@core/enums';

@Component({
    selector: 'app-right-menu',
    styleUrls: ['./app.right-menu.component.scss'],
    templateUrl: './app.right-menu.component.html'
})
export class AppRightMenuComponent implements OnDestroy {

    public quickSearchForm: UntypedFormGroup = new UntypedFormGroup({
        Player: new UntypedFormGroup({
            PlayerId: new UntypedFormControl(''),
            Username: new UntypedFormControl(''),
            Name: new UntypedFormControl(''),
            Email: new UntypedFormControl('',
                [OnlyWhitespaceValidator(), Validators.email]),
            MobileNumber: new UntypedFormControl('', [
                Validators.pattern(new RegExp(RegexesEnum.MobileNumberPatternRegex, [
                    RegexesEnum.IgnoreCaseFlag,
                    RegexesEnum.MultilineFlag
                ].join('')))
            ]),
            RegistrationIp: new UntypedFormControl(''),
            DocumentNumber: new UntypedFormControl('')
        }),
        Document: new UntypedFormGroup({
            DepositId: new UntypedFormControl(''),
            WithdrawalId: new UntypedFormControl(''),
            InternetBetId: new UntypedFormControl(''),
            ExternalId: new UntypedFormControl('')
        })
    });

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public appMain: AppMainComponent,
        private commonService: CommonService,
        private toastService: ToastService,
        private router: Router,
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onInputKeyDown(groupName: string, controlName: string, rootUrl: string, event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.handleQuickSearch(groupName, controlName, rootUrl, (event.ctrlKey || event.metaKey));
        }
    }

    public onButtonClick(groupName: string, controlName: string, rootUrl: string, event: MouseEvent): void {
        this.handleQuickSearch(groupName, controlName, rootUrl, (event.ctrlKey || event.metaKey) && event.button === 0);
    }

    public handleQuickSearch(groupName: string, controlName: string, rootUrl: string, openInNewTab: boolean): void {
        if (
            this.quickSearchForm.get([groupName, controlName]).valid &&
            this.quickSearchForm.get([groupName, controlName]).value
        ) {
            switch (controlName) {
                case 'PlayerId':
                case 'DepositId':
                case 'WithdrawalId':
                    const url = `${rootUrl}/${this.quickSearchForm.get([groupName, controlName]).value.trim()}`;
                    if (openInNewTab) {
                        window.open(url, '_blank');
                    } else {
                        this.router.navigate([url]);
                    }
                    this.resetAndClose();
                    break;
                default:
                    this.navigateToResultListPage(groupName, controlName, rootUrl, openInNewTab);
                    this.resetAndClose();
            }
        }
    }

    private navigateToResultListPage(groupName: string, controlName: string, rootUrl: string, openInNewTab: boolean): void {
        const queryParams = {};
        queryParams[controlName.toLowerCase()] = this.quickSearchForm.get([groupName, controlName]).value;
        const url = this.router.createUrlTree([`/${rootUrl}`], { queryParams }).toString();
        if (openInNewTab) {
            window.open(url, '_blank');
        } else {
            this.router.navigate([`/${rootUrl}`], { queryParams });
        }
    }


    private resetAndClose(): void {
        this.quickSearchForm.reset();
        this.appMain.onLayoutClick();
    }
}
