export enum PasswordRegExpEnum {
    Uppercase = '',
    IsUppercaseRequired = '(?=.*[A-Z])',
    UnableUppercase = '(?!.*[A-Z])',
    Lowercase = '',
    IsLowercaseRequired = '(?=.*[a-z])',
    UnableLowercase = '(?!.*[a-z])',
    Symbol = '',
    IsSymbolRequired = '(?=.*[`!@#$%^&*()\\|_+\\-=\\[\\]{};\':"\\,.<>\\/?~])',
    UnableSymbol = '(?!.*[`!@#$%^&*()\\|_+\\-=\\[\\]{};\':"\\,.<>\\/?~])',
    Numeric = '',
    IsNumericRequired = '(?=.*[0-9])',
    UnableNumeric = '(?!.*[0-9])',
}
