<div class="layout-footer">
    <div class="footer-logo-container">
        <img id="footer-logo"
             [src]="configService.selectedTheme?.PrimaryShortLogo"
             alt="logo"/>
    </div>
    <span
        *ngIf="!appMain.isDesktop() || !(appMain.staticMenuDesktopInactive && appMain.isStatic())"
        class="copyright">{{'PoweredBy' | translate}}, {{'versionShort' | translate}}{{projectVersion}}</span>
</div>
