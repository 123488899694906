export enum PartnerDomainTypeEnum {
    Web = 1,
    Admin = 2,
    Progressive = 3,
    ApiUrl= 4
}

export enum PartnerSegmentTypeEnum {
    VIP = 1,
    Standart = 2
}
