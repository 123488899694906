import { AbstractControl, ValidatorFn } from '@angular/forms';

export function maxSizeExceededValidator(maxSizeInBytes: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value?.size > maxSizeInBytes) {
            return { maxSizeExceeded: true }; 
        }
        return null;
    }
}

export function extensionDeniedValidator(extensions: string | string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (typeof extensions === 'string') {
            switch (extensions) {
                case 'image/*':
                    extensions = [
                        'jpg', 
                        'jpeg', 
                        'png', 
                        'gif', 
                        'bmp', 
                        'webp', 
                        'svg', 
                        'ico', 
                        'tif',
                        'tiff', 
                    ];
                    break;
            }
        }

        const ext = control.value?.name.split('.').pop();
        
        if (extensions.indexOf(ext) === -1) {
            return { extensionDenied: true };
        }
        return null;
    }
}